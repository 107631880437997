import URLs from "../config";

export const auth = {
  LOG_IN: URLs.API + "/api/login",
};
export const profile = {
  LIST: URLs.API + "/api/profiles",
  ASSETS: URLs.API + "/api/assets",
  DELETE: URLs.API + "/api/profiles/",
  ADD: URLs.API + "/api/profiles",
  UPLOAD: URLs.API + "/api/upload",
  PARTICULAR: URLs.API + "/profile/particular/",
  ADMIN_PARTICULAR: URLs.API + "/api/profiles/",
  UPDATE: URLs.API + "/profile/update/",
};
