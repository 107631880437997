import axios from "axios";
import { profile } from "./url";

const getAllProfile = (body) => {
  return axios.get(profile.LIST, body);
};
const addProfile = (body) => {
  return axios.post(profile.ADD, body);
};
const uploadImage = (body) => {
  return axios.post(profile.UPLOAD, body);
};
const deleteProfile = (id) => {
  return axios.delete(profile.DELETE + id);
};
const getParticularProfile = (id) => {
  return axios.get(profile.PARTICULAR + id);
};
const adminGetParticularProfile = (id) => {
  return axios.get(profile.ADMIN_PARTICULAR + id);
};
const updateProfile = (data) => {
  return axios.put(profile.ADMIN_PARTICULAR + data?.id, data);
};
const getAsstes = (file) => {
  return axios.get(profile.ASSETS, {
    params: file,
    responseType: "blob",
  });
};

const ProfileService = {
  getAllProfile,
  deleteProfile,
  addProfile,
  getParticularProfile,
  adminGetParticularProfile,
  updateProfile,
  uploadImage,
  getAsstes,
};

export default ProfileService;
